<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1 class="title">Featured Characters</h1>
    <featured-infinite-characters class="my-2" />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import FeaturedInfiniteCharacters from './components/FeaturedInfiniteCharacters.vue';


@Options({
  name: 'FeaturedCharactersPage',
  components: {
    FeaturedInfiniteCharacters,
  },
})
export default class FeaturedCharactersPage extends Vue {
  public async created() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
}
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
.title
  font-weight: bold
</style>
