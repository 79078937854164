<template>
  <div>
    <div v-if="characters && characters.length">
      <character-card-small
        v-for="character of characters"
        :key="character.id"
        :character="character"
        :comments-count="get(charCommentsCounts, `${character.id}.comments_count`) || 0"
        :show-comment-counts="true"
        class="my-3"
      />
      <p v-if="nextPageExists" class="clickable" @click="requestLoadMore">Load More</p>
      <p v-if="!nextPageExists">End of list</p>
    </div>
    <div v-else class="no-data">No featured characters yet</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import { Character, Paging } from '@/shared/types/static-types';

import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';
import { getFeaturedCharacters } from '@/shared/actions/characters';
import { getNextPage } from '@/shared/helpers/pagination';

@Options({
  name: 'FeaturedInfiniteCharacters',
  components: {
    CharacterCardSmall,
  },
})
export default class FeaturedInfiniteCharacters extends Vue {
  public charCommentsCounts = {};
  public areCommentCountsLoading = false;
  public characters: Character[] = [];
  public paging: Paging | null = null;

  get = get;

  @Watch('characters')
  charactersChanged() {
    if (get(this.characters, 'length')) {
      this.fetchCharsCommentCounts();
    }
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async fetchCharsCommentCounts() {
    this.areCommentCountsLoading = true;
    this.charCommentsCounts = {};
    const resp = await getCommentsCount(map(this.characters, 'id'), constants.commentedAsTypes.CHARACTER);
    this.charCommentsCounts = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
    this.areCommentCountsLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    const { results, ...paging } = await getNextPage(this.paging!);
    this.characters = this.characters.concat(map(results, 'character'));
    this.paging = paging;
  }

  public async fetchFeaturedCharacters() {
    const { results, ...paging } = await getFeaturedCharacters(1);
    this.characters = map(results, 'character');
    this.paging = paging;
  }

  created() {
    this.fetchFeaturedCharacters();
  }
}
</script>

<style scoped lang="sass"></style>
